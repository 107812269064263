import styled, { css } from 'styled-components';

import { above } from '../../../styles/libs';

const sizes = {
  mobile: {
    xs: 'FIELD_SIZE_XS',
    small: 'FIELD_SIZE_SMALL',
    medium: 'FIELD_SIZE_MEDIUM',
    large: 'FIELD_SIZE_MEDIUM',
  },
  desktop: {
    xs: 'FIELD_SIZE_XS',
    small: 'FIELD_SIZE_SMALL',
    medium: 'FIELD_SIZE_MEDIUM',
    large: 'FIELD_SIZE_LARGE',
  },
};

export default styled.form`
  display: block;
`;

export const focusState = css`
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
  }
`;

export const focusStateInset = css`
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK} inset;
  }
`;

export const focusStateReset = css`
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const cssField = css`
  ${({ block }) => (block ? 'width: 100%;' : null)}
  ${({ marginBottom }) => marginBottom && ((typeof marginBottom === 'string') ? css`margin-bottom: ${marginBottom};` : css`margin-bottom: 1em;`)}
  ${({ marginTop }) => marginTop && ((typeof marginTop === 'string') ? css`margin-top: ${marginTop};` : css`margin-top: 1em;`)}
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  border-radius: 1px;
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.TEXT_BODY_FONT};
  height: ${({ size, theme }) => theme[sizes.desktop[size]]};
  padding: ${({ size }) => (size === 'small' ? '0 0.625rem' : '0 1rem')};

  ${({ theme }) => above(theme.DESKTOP)`
    font-size: 0.875rem;
  `}

  ${({ error, theme }) => error && css`
    border-color: ${theme.COLOR_DANGER};
  `}

  ${({ customStyled }) => customStyled && css`${customStyled}`}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const field = css`
  ${cssField}
`;

export const disabledCSS = css`
background-color: ${({ theme }) => theme.COLOR_WHITE};
color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
pointer-events: none;
`;
