import styled, { css } from 'styled-components';

export default styled.i`
  display: inline-block;

  ${({ color, size }) => css`
    color: ${color};
    font-size: ${size};
    line-height: ${size};
    ${({ originalShape }) => `${originalShape ? '' : `width: ${size}; min-width: ${size};`}`}
    height: ${size};
  `}

  margin: ${({ margin }) => margin || 0};
  ${({ marginLeft }) => marginLeft && ((typeof marginLeft === 'string') ? css`margin-left: ${marginLeft};` : css`margin-left: 1rem;`)}
  ${({ marginRight }) => marginRight && ((typeof marginRight === 'string') ? css`margin-right: ${marginRight};` : css`margin-right: 1rem;`)}

  & > svg, & > img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const SafariIconFixSpan = styled.span`
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;
