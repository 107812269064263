export default {
  // Apollo Client first executes the query against the cache. If all requested data
  // is present in the cache, that data is returned. Otherwise, Apollo Client executes
  // the query against your GraphQL server and returns that data after caching it.
  // Prioritizes minimizing the number of network requests sent by your application.
  // This is the default fetch policy.
  CACHE_FIRST: 'cache-first',
  // Apollo Client executes the query only against the cache. It never queries your
  // server in this case.
  // A cache-only query throws an error if the cache does not contain data for all requested fields.
  CACHE_ONLY: 'cache-only',
  // Apollo Client executes the full query against both the cache and your GraphQL server.
  // The query automatically updates if the result of the server-side query modifies cached fields.
  // Provides a fast response while also helping to keep cached data consistent with server dat
  CACHE_AND_NETWORK: 'cache-and-network',
  // Apollo Client executes the full query against your GraphQL server, without first
  // checking the cache. The query's result is stored in the cache.
  // Prioritizes consistency with server data, but can't provide a near-instantaneous
  // response when cached data is available.
  NETWORK_ONLY: 'network-only',
  // Similar to network-only, except the query's result is not stored in the cache.
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
};
