import styled, { css } from 'styled-components';

const cssText = css`
  ${({ inline }) => inline && css`display: inline-block;`}
  margin: 0;
  color: ${({ color }) => color || 'inherit'};
  ${({ marginBottom }) => marginBottom && ((typeof marginBottom === 'string') ? css`margin-bottom: ${marginBottom};` : css`margin-bottom: 1em;`)}
  ${({ marginTop }) => marginTop && ((typeof marginTop === 'string') ? css`margin-top: ${marginTop};` : css`margin-top: 1em;`)}
  ${({ paragraph }) => paragraph && css`
    display: block;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  `}
  ${({ align }) => align && css`text-align: ${align};`}
  ${({ size }) => size && css`font-size: ${size};`}
  ${({ weight }) => weight && css`font-weight: ${weight};`}
  ${({ fontStyle }) => fontStyle && css`font-style: ${fontStyle};`}
  word-wrap: break-word;
  ${({ wrap }) => wrap && css`
    word-break: break-all;
    white-space: normal;
  `}
`;

export const Span = styled.span`${cssText}`;
export const P = styled.p`${cssText}`;

export const Ul = styled.ul`
  margin: 0;
  padding-left: 1.125rem;

  ${({ marginBottom }) => marginBottom && css`margin-bottom: 1em;`}
  ${({ marginTop }) => marginTop && css`margin-top: 1em;`}
`;

export const Li = styled.li`
  padding-left: 0.5rem;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default P;
