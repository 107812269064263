import React from 'react';
import PropTypes from 'prop-types';

import svgLoading from '../../../static/images/icons/Loading.svg';

import StyledLoadingSpinner, { Spinner, Text } from './LoadingSpinner.style';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  dictionary: PropTypes.string,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  inline: PropTypes.bool,
  size: PropTypes.string,
  fillHeight: PropTypes.bool,
  style: PropTypes.shape(),
};

const defaultProps = {
  children: null,
  className: '',
  color: 'inherit',
  dictionary: '',
  marginTop: false,
  marginBottom: false,
  inline: false,
  size: '3rem',
  fillHeight: false,
  style: {},
};

function LoadingSpinner(props) {
  return (
    <StyledLoadingSpinner
      className={props.className}
      style={props.style}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      inline={props.inline}
      fillHeight={props.fillHeight}
    >
      <Spinner icon={svgLoading} color={props.color} size={props.size} />
      {props.dictionary && !props.children && <Text dictionary={props.dictionary} />}
      {props.children && <Text>{props.children}</Text>}
    </StyledLoadingSpinner>
  );
}

LoadingSpinner.propTypes = propTypes;
LoadingSpinner.defaultProps = defaultProps;

export default LoadingSpinner;
