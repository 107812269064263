import styled, { css } from 'styled-components';

import { above } from '../../../styles/libs';
import detectIE from '../../../lib/detectIE';
import { focusStateInset } from '../Form/Form.style';

const sizes = {
  mobile: {
    small: 'BUTTON_SIZE_SMALL',
    medium: 'BUTTON_SIZE_MEDIUM',
    large: 'BUTTON_SIZE_LARGE',
    xl: 'BUTTON_SIZE_LARGE',
  },
  desktop: {
    small: 'BUTTON_SIZE_SMALL',
    medium: 'BUTTON_SIZE_MEDIUM',
    large: 'BUTTON_SIZE_LARGE',
    xl: 'BUTTON_SIZE_XL',
  },
};

const alignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

function setMinWidth(minWidth) {
  return typeof minWidth === 'boolean' ? '9rem' : minWidth;
}

function fontSize(size, desktop) {
  switch (size) {
    case 'small':
      return '11px'; // was 0.75rem WIP to align with designs
    case 'medium':
      return '0.875rem';
    case 'xl':
      return desktop && '0.875rem';
    default:
      return '0.8125rem';
  }
}

export const buttonSmall = css`
  font-size: ${fontSize('small', true)};
  line-height: 16px;
  min-height: ${({ theme }) => theme.BUTTON_SIZE_SMALL};
  ${!!detectIE() && css`
    height: ${({ theme }) => theme.BUTTON_SIZE_SMALL};
  `}
  ${focusStateInset}
`;

export const cssButtonLarge = css`
  font-size: ${fontSize('large', true)};
  min-height: ${({ theme }) => theme.BUTTON_SIZE_LARGE};
  ${!!detectIE() && css`
    height: ${({ theme }) => theme.BUTTON_SIZE_LARGE};
  `}
  ${focusStateInset}
`;

export const cssButtonXL = css`
  font-size: ${fontSize('xl', true)};
  min-height: ${({ theme }) => theme.BUTTON_SIZE_XL};
  ${!!detectIE() && css`
    height: ${({ theme }) => theme.BUTTON_SIZE_XL};
  `}
  ${focusStateInset}
`;

export const buttonCSS = css`
  ${({ marginBottom }) => marginBottom && (typeof marginBottom === 'string' ? css`
    margin-bottom: ${marginBottom};
  ` : css`
    margin-bottom: 1rem;
  `)}
  ${({ marginLeft }) => marginLeft && (typeof marginLeft === 'string' ? css`
    margin-left: ${marginLeft};
  ` : css`
    margin-left: 1rem;
  `)}
  ${({ marginRight }) => marginRight && (typeof marginRight === 'string' ? css`
    margin-right: ${marginRight};
  ` : css`
    margin-right: 1rem;
  `)}
  ${({ marginTop }) => marginTop && (typeof marginTop === 'string' ? css`
    margin-top: ${marginTop};
  ` : css`
    margin-top: 1rem;
  `)}
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border: 2px solid transparent;
  border-radius: 5px;
  color: ${({ color, theme }) => color || theme.COLOR_TEXT_BODY};
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.4;
  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  padding: 0;
  text-transform: uppercase;
  transition: all 250ms ease;
  user-select: none;
  ${({ block }) => (block ? 'width: 100%;' : null)}
  ${!!detectIE() && css`
    height: ${({ size, theme }) => theme[sizes.mobile[size]]};
  `}

  &:disabled {
    cursor: default;
  }
  ${({ disabled }) => disabled && css`
    cursor: default;
    pointer-events: none;
  `}
  ${({ noPadding, size, theme }) => css`
    font-size: ${fontSize(size)};
    ${!noPadding && `min-height: ${theme[sizes.mobile[size]]};`}
  `}
  ${({ noPadding, size, theme }) => above(theme.DESKTOP)`
    font-size: ${fontSize(size, true)};
    ${!noPadding && `min-height: ${theme[sizes.desktop[size]]};`}
  `}
  ${focusStateInset}
`;

export const Body = styled.span`
  display: flex;
  align-items: center;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.3;
  justify-content: ${({ align }) => alignment[align]};
  ${({ minWidth }) => minWidth && css`
    min-width: ${setMinWidth(minWidth)};
  `}
  ${({ direction }) => direction && css`
    flex-direction: ${direction};
  `}
  padding: ${({ noPadding }) => (noPadding ? '0' : '0.25rem 0.75rem')};
  width: 100%;
  height: 100%;
  ${({ size, noPadding, theme }) => above(theme.DESKTOP)`
    font-size: ${fontSize(size, true)};
    ${!noPadding && `min-height: ${theme[sizes.desktop[size]]};`}

    ${!!detectIE() && css`
      min-height: auto;
    `}
  `}
  text-transform:  ${({ textTransform }) => (textTransform || 'uppercase')};
  text-decoration: ${({ textDecoration }) => (textDecoration || 'none')};

  ${({ isLoading }) => isLoading && 'opacity: 0;'}
`;

export const Button = styled.button`
  ${buttonCSS}
`;

export const AnchorButton = styled.a`
  ${buttonCSS}
`;

export default Button;
