import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import GET_DICTIONARY_ITEM from './getDictionaryItem.gql';

/**
 * Looks up the dictionary item in the cache by key, if the item does not exist
 * an attempt to fetch will be made, and the value or key will be returned.
 */
const withDictionary = (WrappedComponent, options = {}) => class extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    text: PropTypes.string,
    disabled: PropTypes.bool,
    dictionary: PropTypes.string,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    responsiveHeight: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    dictionary: '',
    text: '',
    disabled: false,
    isLoading: false,
    onClick: undefined,
    responsiveHeight: false,
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.disabled !== nextProps.disabled) return true;
    if (this.props.children !== nextProps.children) return true;
    if (this.props.dictionary !== nextProps.dictionary) return true;
    if (this.props.isLoading !== nextProps.isLoading) return true;
    if (this.props.onClick !== nextProps.onClick) return true;
    if (this.props.responsiveHeight !== nextProps.responsiveHeight) return true;
    return nextProps.text !== this.props.text;
  }

  render() {
    if (!this.props.dictionary) {
      return (
        <WrappedComponent {...this.props}>
          {this.props.children}
        </WrappedComponent>
      );
    }

    const dictionaryKey = `${this.props.dictionary}__${options.name || 'Component'}`;

    return (
      <Query
        query={GET_DICTIONARY_ITEM}
        variables={{ key: dictionaryKey }}
      >
        {({ data, variables }) => {
          if (!data) return null;

          const { dictionaryItem } = data;

          const text = dictionaryItem && (dictionaryItem.value || dictionaryItem.value === '') ?
            dictionaryItem.value :
            variables.key;

          return (
            <WrappedComponent {...this.props} dictionary={dictionaryKey}>
              {text}
            </WrappedComponent>
          );
        }}
      </Query>
    );
  }
};

export default withDictionary;
