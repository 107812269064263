import React from 'react';
import PropTypes from 'prop-types';

import withDictionary from '../../../hocs/withDictionary';

import { P, Span } from './Text.style';

const defaultProps = {
  align: '',
  children: null,
  className: '',
  color: '',
  'data-testid': '',
  dictionary: '',
  innerRef: null,
  inline: false,
  marginBottom: false,
  marginTop: false,
  paragraph: false,
  size: '',
  span: false,
  style: null,
  title: '',
  weight: '',
  fontStyle: undefined,
};

const propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  color: PropTypes.string,
  'data-testid': PropTypes.string,
  dictionary: PropTypes.string,
  innerRef: PropTypes.shape({}),
  inline: PropTypes.bool,
  marginBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  marginTop: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  paragraph: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  span: PropTypes.bool,
  style: PropTypes.shape({}),
  title: PropTypes.string,
  weight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  fontStyle: PropTypes.string,
};

function Text({
  children,
  dictionary,
  innerRef,
  span,
  ...props
}) {
  const Component = span ? Span : P;
  return (
    <Component
      data-testid={props['data-testid']}
      data-dictionary={dictionary}
      ref={innerRef}
      {...props}
    >
      {children}
    </Component>
  );
}

Text.defaultProps = defaultProps;
Text.propTypes = propTypes;

export default withDictionary(Text, { name: 'Text' });
