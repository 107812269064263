import styled, { css, keyframes } from 'styled-components';

import IconComponent from '../Icon';
import TextComponent from '../Text';

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export default styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ fillHeight }) => fillHeight && 'min-height: 100%;'}
  ${({ marginTop }) => marginTop && 'margin-top: 2rem;'}
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 2rem;'}
  ${({ inline }) => inline && css`
    display: inline;
    margin-left: 0.25rem;
  `}
`;

export const Spinner = styled(IconComponent)`
  animation: 1000ms ${rotate} linear infinite;
  color: ${({ color }) => color || 'inherit'};
`;

export const Text = styled(TextComponent)`
  color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: center;
`;
