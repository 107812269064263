import styled, { css } from 'styled-components';

import Button from './Button';
import { buttonCSS } from './Button.style';

export const buttonBrandCSS = css`
  ${buttonCSS}
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  color: ${({ theme }) => theme.COLOR_WHITE};
  animation: ${props => props.pulse === true && 'pulse ease-out 0.8s forwards'};

  @keyframes pulse {
    0% {
      background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
    }

    30% {
      background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_LIGHT};
    }

    100% {
      background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.COLOR_GRAY};
    color: ${({ theme }) => theme.COLOR_WHITE};
  }
  ${({ disabled, theme }) => disabled && css`
    background-color: ${() => theme.COLOR_GRAY};
    color: ${() => theme.COLOR_WHITE};
  `}
  ${({ hidden }) => hidden && css`
    display: none;
  `}
  ${({ flat }) => flat && css`
    border-radius: 0;
  `}
`;

// <Button> component has a delay in prop value
export const SimpleButton = styled.button`
  ${buttonBrandCSS}
  padding: 1rem 3rem;
  margin: 1rem auto;
  text-align: center;
`;

export default styled(Button)`
  ${buttonBrandCSS}
`;
