import gql from 'graphql-tag';

/**
 * Synchronous helper to get dictionary values straight from the cache
 *
 * @param {ApolloClient} client ApolloClient
 * @param {string} key Dictionary String
 *
 * @returns {string | undefined}
 */
export function getDictionaryItem(client, key) {
  let value;
  try {
    const { dictionaryItem: item } = client.readQuery({
      query: gql`
        query DictionaryItem {
          dictionaryItem(key: "${key}") {
            value
          }
        }
      `,
    });
    value = item.value;
  } catch (e) {
    value = '';
  }

  return value;
}

/**
 * Synchronous helper to get a series of dictionary values from the cache
 * mapped to new readable keys.
 *
 * @param {ApolloClient} client ApolloClient
 * @param {object} mapObject object who's values are the dictionary keys are readable
 * keys to map to.
 *
 * @returns {object | undefined}
 */
export function mapDictionaryItems(client, mapObject) {
  const keyValues = Object.entries(mapObject).reduce((acc, [readableKey, dictionaryKey]) => {
    acc[readableKey] = getDictionaryItem(client, dictionaryKey);
    return acc;
  }, {});

  return keyValues;
}
