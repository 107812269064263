import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

import StyledIcon, { SafariIconFixSpan } from './Icon.style';
import testingAttr from '../../../lib/testingAttr';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  color: PropTypes.string,
  icon: PropTypes.string,
  marginLeft: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  marginRight: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  size: PropTypes.string,
  style: PropTypes.shape(),
  testingId: PropTypes.string,
  margin: PropTypes.string,
  originalShape: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  color: '',
  icon: '',
  size: '1em',
  style: {},
  className: '',
  children: null,
  marginLeft: false,
  marginRight: false,
  testingId: '',
  margin: '',
  originalShape: false,
  onClick: () => {},
};

function Icon({
  children,
  color,
  icon,
  size,
  style,
  className,
  testingId,
  margin,
  marginLeft,
  marginRight,
  originalShape,
  onClick,
}) {
  return (
    <>
      <StyledIcon
        color={color}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(icon || children) }}
        size={size}
        style={style}
        className={className}
        margin={margin}
        marginLeft={marginLeft}
        marginRight={marginRight}
        originalShape={originalShape}
        onClick={onClick}
        {...testingAttr(testingId)}
      />
      {/*
        This span is required to have icons render properly in checkboxes in iOS 17.4.X
        and potentially above. I came across this fix accidentally, as I could not find
        anyone online having this issue. It's likely something to do with our custom
        implementation of checkboxes. Custom styles are so it doesn't interfere with
        any clickable icons
      */}
      <SafariIconFixSpan />
    </>
  );
}

Icon.defaultProps = defaultProps;
Icon.propTypes = propTypes;

export default memo(Icon);
