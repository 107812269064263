const getFetchPolicyLocalStorageKey = (key) => 'FetchPolicyExpiryKey_{KEY}'.replace('{KEY}', key);

const getFetchPolicyWithExpiry = (key, options) => {
  const localStorageKey = getFetchPolicyLocalStorageKey(key);

  const policyExpiry = window.localStorage.getItem(localStorageKey);
  const nowMs = new Date().getTime();

  if (policyExpiry) {
    const expiryMs = parseInt(policyExpiry, 10);
    if (expiryMs + options.expiry < nowMs) {
      window.localStorage.setItem(localStorageKey, nowMs);
      return options.expiryPolicy;
    }
  } else {
    window.localStorage.setItem(localStorageKey, nowMs);
  }

  return options.defaultPolicy;
};

export default getFetchPolicyWithExpiry;
